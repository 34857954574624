<template>
  <div class="container mt-5">
    <h1>管理者ダッシュボード</h1>
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <router-link to="/admin-dashboard/key-list" class="nav-link">Key一覧</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/admin-dashboard/key-manage" class="nav-link">Key管理</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/admin-dashboard/key-add" class="nav-link">Key追加</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/admin-dashboard/user-list" class="nav-link">ユーザー一覧</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/admin-dashboard/user-manage" class="nav-link">ユーザー管理</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/admin-dashboard/user-add" class="nav-link">ユーザー追加</router-link>
      </li>
    </ul>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'AdminDashboard'
};
</script>