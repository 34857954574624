<template>
  <div class="container mt-5">
    <h2>個人情報</h2>
    <form @submit.prevent="saveUser" class="row g-3">
      <div class="col-md-6">
        <label for="ID" class="form-label">ユーザーID</label>
        <input id="ID" v-model="userDetail.ID" class="form-control" disabled />
      </div>
      <div class="col-md-6">
        <label for="USERNAME" class="form-label">アカウント</label>
        <input id="USERNAME" v-model="userDetail.USERNAME" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-6">
        <label for="COMPANY_NAME" class="form-label">会社名</label>
        <input id="COMPANY_NAME" v-model="userDetail.COMPANY_NAME" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-6">
        <label for="COMPANY_ID" class="form-label">会社ID</label>
        <input id="COMPANY_ID" v-model="userDetail.COMPANY_ID" class="form-control" disabled />
      </div>
      <div class="col-md-6">
        <label for="IS_ADMIN" class="form-label">管理者権限</label>
        <input type="checkbox" id="IS_ADMIN" v-model="userDetail.IS_ADMIN" class="form-check-input" :disabled="!isEditing" />
      </div>
      <div class="col-md-6" v-if="isEditing">
        <label for="PASSWORD" class="form-label">新しいパスワード</label>
        <input type="password" id="PASSWORD" v-model="userDetail.PASSWORD" class="form-control" />
      </div>
      <div class="col-12">
        <button type="button" @click="isEditing = !isEditing" class="btn btn-primary me-2">{{ isEditing ? 'キャンセル' : '編集' }}</button>
        <button type="submit" v-if="isEditing" class="btn btn-success">保存</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from '@/axios'; // 使用创建的 axios 实例

export default {
  data() {
    return {
      userDetail: {},
      isEditing: false
    };
  },
  created() {
    this.fetchUserDetail(this.$route.params.user_id);
  },
  methods: {
    async fetchUserDetail(user_id) {
      try {
        const response = await axios.get(`/api/users/${user_id}/detail`);
        this.userDetail = response.data;
      } catch (error) {
        console.error('個人情報を取得に失敗しました。:', error);
      }
    },
    async saveUser() {
      try {
        const data = {
          USERNAME: this.userDetail.USERNAME,
          COMPANY_NAME: this.userDetail.COMPANY_NAME,
          IS_ADMIN: this.userDetail.IS_ADMIN,
          PASSWORD: this.userDetail.PASSWORD
        };
        await axios.put(`/api/users/${this.userDetail.ID}/detail`, data);
        alert('個人情報を保存しました。');
        this.isEditing = false;
      } catch (error) {
        console.error('個人情報を保存に失敗しました。:', error);
        alert('個人情報を保存に失敗しました。');
      }
    }
  }
};
</script>