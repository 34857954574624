<template>
  <div class="container mt-5">
    <h2>ユーザー追加</h2>
    <form @submit.prevent="addUser" class="row g-3">
      <div class="col-md-6">
        <label for="username" class="form-label">ユーザーアカウント</label>
        <input id="username" v-model="newUser.username" class="form-control" required />
      </div>
      <div class="col-md-6">
        <label for="password" class="form-label">ユーザーパスワード</label>
        <input id="password" v-model="newUser.password" class="form-control" required />
      </div>
      <div class="col-md-6">
        <label for="company_name" class="form-label">会社名</label>
        <input id="company_name" v-model="newUser.company_name" class="form-control" required />
      </div>
      <div class="col-md-6">
        <label for="is_admin" class="form-label">管理者権限</label>
        <select id="is_admin" v-model="newUser.is_admin" class="form-select" required>
          <option value="true">あり</option>
          <option value="false">なし</option>
        </select>
      </div>
      <div class="col-12">
        <button type="submit" class="btn btn-primary">ユーザー追加</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from '@/axios'; // 使用创建的 axios 实例

export default {
  data() {
    return {
      newUser: {
        username: '',
        password: '',
        company_name: '',
        is_admin: 'false'
      }
    };
  },
  methods: {
    async addUser() {
      try {
        const response = await axios.post('/api/users', this.newUser);
        alert(`ユーザーを追加しました。: ${JSON.stringify(response.data.user)}`);
        this.$router.push('/admin-dashboard/user-add');
      } catch (error) {
        console.error('ユーザーを追加に失敗しました:', error);
        if (error.response) {
          alert(`ユーザーを追加に失敗しました: ${error.response.data.error}`);
        } else if (error.request) {
          alert('ユーザーを追加に失敗しました: リクエストは送信されましたが応答はありません。');
        } else {
          alert('ユーザーを追加に失敗しました: 配置エラー。');
        }
      }
    }
  }
};
</script>