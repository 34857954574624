<template>
  <div class="container mt-5">
    <h2>ユーザー一覧</h2>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>ユーザーID</th>
          <th>アカウント</th>
          <th>会社名</th>
          <th>会社ID</th>
          <th>管理者権限</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.ID">
          <td><router-link :to="`/admin-dashboard/user-detail/${user.ID}`">{{ user.ID }}</router-link></td>
          <td><router-link :to="`/admin-dashboard/user-detail/${user.ID}`">{{ user.USERNAME }}</router-link></td>
          <td>{{ user.COMPANY_NAME }}</td>
          <td>{{ user.COMPANY_ID }}</td>
          <td>{{ user.IS_ADMIN }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from '@/axios'; // 使用创建的 axios 实例

export default {
  data() {
    return {
      users: []
    };
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    async fetchUsers() {
      try {
        const response = await axios.get('/api/users');
        this.users = response.data;
      } catch (error) {
        console.error('ユーザーリストを取得に失敗しました:', error);
      }
    }
  }
};
</script>