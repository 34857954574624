<template>
  <div class="container mt-5">
    <h2>Key一覧</h2>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>KEY ID</th>
          <th>会社名</th>
          <th>Key状態</th>
          <th>名刺管理権限</th>
          <th>配信権限</th>
          <th>名刺利用量</th>
          <th>名刺利用制限</th>
          <th>配信利用量</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="key in keys" :key="key.KEY_ID">
          <td><router-link :to="`/user-dashboard/key-detail/${key.KEY_ID}`">{{ key.KEY_ID }}</router-link></td>
          <td>{{ key.COMPANY_NAME }}</td>
          <td>{{ key.STATUS }}</td>
          <td>{{ key.MEISHI_PERMISSION ? 'あり' : 'なし' }}</td>
          <td>{{ key.MAIL_PERMISSION ? 'あり' : 'なし' }}</td>
          <td>{{ key.CURRENT_USAGE }}</td>
          <td>{{ key.TOTAL_USAGE_LIMIT }}</td>
          <td>{{ key.MAIL_USAGE }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from '@/axios'; // 使用创建的 axios 实例

export default {
  data() {
    return {
      keys: []
    };
  },
  created() {
    this.fetchMyKeys();
  },
  methods: {
    async fetchMyKeys() {
      try {
        const response = await axios.get('/api/user-keys');
        this.keys = response.data;
      } catch (error) {
        console.error('Keyを取得に失敗しました:', error);
      }
    }
  }
};
</script>

<!-- <template>
  <div class="container mt-5">
    <h2>Key一覧</h2>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>KEY ID</th>
          <th>会社名</th>
          <th>Key状態</th>
          <th>名刺管理権限</th>
          <th>配信権限</th>
          <th>名刺利用量</th>
          <th>名刺利用制限</th>
          <th>配信利用量</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="key in keys" :key="key.KEY_ID">
          <td>{{ key.KEY_ID }}</td>
          <td>{{ key.COMPANY_NAME }}</td>
          <td><router-link :to="`/user-dashboard/key-detail/${key.KEY_ID}`">{{ key.COMPANY_NAME }}</router-link></td>
          <td>{{ key.STATUS }}</td>
          <td>{{ key.MEISHI_PERMISSION }}</td>
          <td>{{ key.MAIL_PERMISSION }}</td>
          <td>{{ key.CURRENT_USAGE }}</td>
          <td>{{ key.TOTAL_USAGE_LIMIT }}</td>
          <td>{{ key.MAIL_USAGE }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from '@/axios'; // 使用创建的 axios 实例

export default {
  data() {
    return {
      keys: []
    };
  },
  created() {
    this.fetchMyKeys();
  },
  methods: {
    async fetchMyKeys() {
      try {
        const response = await axios.get('/api/user-keys');
        this.keys = response.data;
      } catch (error) {
        console.error('Keyを取得に失敗しました:', error);
      }
    }
  }
};
</script> -->