<template>
  <div class="container mt-5">
    <h2>ユーザー管理</h2>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>ユーザーID</th>
          <th>ユーザーアカウント</th>
          <th>会社名</th>
          <th>会社ID</th>
          <th>管理者権限</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.ID">
          <td><router-link :to="`/admin-dashboard/user-detail/${user.ID}`">{{ user.ID }}</router-link></td>
          <td><router-link :to="`/admin-dashboard/user-detail/${user.ID}`">{{ user.USERNAME }}</router-link></td>
          <td v-if="!user.editing">{{ user.COMPANY_NAME }}</td>
          <td v-else><input v-model="user.company_name" class="form-control" /></td>
          <td>{{ user.COMPANY_ID }}</td>
          <td v-if="!user.editing">{{ user.IS_ADMIN }}</td>
          <td v-else><input type="checkbox" v-model="user.is_admin" /></td>
          <td>
            <button @click="toggleEdit(user)" class="btn btn-primary me-2">{{ user.editing ? '保存' : '編集' }}</button>
            <button @click="deleteUser(user.ID)" class="btn btn-danger">削除</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from '@/axios'; // 使用创建的 axios 实例

export default {
  data() {
    return {
      users: []
    };
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    async fetchUsers() {
      try {
        const response = await axios.get('/api/users');
        this.users = response.data.map(user => ({ ...user, editing: false, username: user.USERNAME, company_name: user.COMPANY_NAME, is_admin: user.IS_ADMIN }));
      } catch (error) {
        console.error('ユーザーリストを取得に失敗しました:', error);
      }
    },
    toggleEdit(user) {
      if (user.editing) {
        this.saveUser(user);
        user.USERNAME = user.username;
        user.COMPANY_NAME = user.company_name;
        user.IS_ADMIN = user.is_admin;
      }
      user.editing = !user.editing;
    },
    async saveUser(user) {
      try {
        await axios.put(`/api/users/${user.ID}`, {
          username: user.username,
          company_name: user.company_name,
          is_admin: user.is_admin
        });
      } catch (error) {
        console.error('ユーザーを保存に失敗しました:', error);
      }
    },
    async deleteUser(userId) {
      if (confirm('削除を確認しますか?')) {
        try {
          await axios.delete(`/api/users/${userId}`);
          this.fetchUsers();
        } catch (error) {
          console.error('ユーザーを削除失敗しました:', error);
        }
      }
    }
  }
};
</script>