import { createStore } from 'vuex';

export default createStore({
  state: {
    username: null,
    userId: null
  },
  mutations: {
    setUsername(state, username) {
      state.username = username;
    },
    setUserId(state, userId) {
      state.userId = userId;
    }
  },
  actions: {
  },
  modules: {
  }
});