<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">ユーザー登録</div>
          <div class="card-body">
            <form @submit.prevent="register">
              <div class="mb-3">
                <label for="username" class="form-label">アカウント</label>
                <input type="text" class="form-control" id="username" v-model="username" required>
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">パスワード</label>
                <input type="password" class="form-control" id="password" v-model="password" required>
              </div>
              <div class="mb-3">
                <label for="company_name" class="form-label">会社名</label>
                <input type="text" class="form-control" id="company_name" v-model="company_name" required>
              </div>
              <button type="submit" class="btn btn-primary">登録</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'; // 使用创建的 axios 实例

export default {
  data() {
    return {
      username: '',
      password: '',
      company_name: ''
    };
  },
  methods: {
    async register() {
      try {
        console.log("Sending data:", {
          username: this.username,
          password: this.password,
          company_name: this.company_name
        });
        const response = await axios.post('/api/user-register', {
          username: this.username,
          password: this.password,
          company_name: this.company_name
        });
        if (response.data.message) {
          alert(response.data.message);
          this.$router.push('/login');
        } else {
          alert('登録失敗');
        }
      } catch (error) {
        console.error('登録失敗:', error);
        alert('登録失敗');
      }
    }
  }
};
</script>