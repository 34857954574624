<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container">
        <router-link to="/" class="navbar-brand">名刺活用+・キー管理システム</router-link>
        <div class="collapse navbar-collapse">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link to="/admin-login" class="nav-link">管理者ログイン</router-link> 
            </li>
            <li class="nav-item">
              <router-link to="/user-login" class="nav-link">ユーザーログイン</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/user-register" class="nav-link">ユーザー登録</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>