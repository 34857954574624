<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">ユーザーログイン</div>
          <div class="card-body">
            <form @submit.prevent="login">
              <div class="mb-3">
                <label for="username" class="form-label">アカウント</label>
                <input type="text" id="username" class="form-control" v-model="username" required>
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">パスワード</label>
                <input type="password" id="password" class="form-control" v-model="password" required>
              </div>
              <button type="submit" class="btn btn-primary">ログイン</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'; // 使用创建的 axios 实例

export default {
  data() {
    return {
      username: '',
      password: ''
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post('/api/user-login', {
          username: this.username,
          password: this.password
        });
        if (response.data.message === 'User login successful') {
          localStorage.setItem('token', response.data.token);
          this.$router.push('/user-dashboard/my-keys');
        } else {
          alert('ログインに失敗しました');
        }
      } catch (error) {
        console.error('ログインに失敗しました:', error);
        if (error.response) {
          alert('ログインに失敗しました: ' + error.response.data.error);
        } else if (error.request) {
          alert('ログインに失敗しました: リクエストは送信されましたが応答はありません。');
        } else {
          alert('ログインに失敗しました: 配置エラー。');
        }
      }
    }
  }
};
</script>