<template>
  <div class="container mt-5">
    <h2>個人情報</h2>
    <div class="mb-3">
      <label for="userId" class="form-label">ユーザーID</label>
      <input type="text" class="form-control" id="userId" v-model="user.ID" disabled>
    </div>
    <div class="mb-3">
      <label for="username" class="form-label">アカウント</label>
      <input type="text" class="form-control" id="username" v-model="user.USERNAME" disabled>
    </div>
    <div class="mb-3">
      <label for="companyName" class="form-label">会社名</label>
      <input type="text" class="form-control" id="companyName" v-model="user.COMPANY_NAME" :disabled="!isEditing">
    </div>
    <div class="mb-3">
      <label for="companyId" class="form-label">会社ID</label>
      <input type="text" class="form-control" id="companyId" v-model="user.COMPANY_ID" disabled>
    </div>
    <div class="mb-3" v-if="isEditing">
      <label for="newPassword" class="form-label">新しいパスワード</label>
      <input type="password" class="form-control" id="newPassword" v-model="newPassword">
    </div>
    <button v-if="!isEditing" @click="isEditing = true" class="btn btn-primary">编辑</button>
    <button v-if="isEditing" @click="saveProfile" class="btn btn-success">保存</button>
    <button v-if="isEditing" @click="isEditing = false" class="btn btn-secondary">取消</button>
  </div>
</template>

<script>
import axios from '@/axios'; // 使用创建的 axios 实例

export default {
  data() {
    return {
      user: {
        ID: '',
        USERNAME: '',
        COMPANY_NAME: '',
        COMPANY_ID: ''
      },
      newPassword: '',
      isEditing: false
    };
  },
  created() {
    this.fetchUserProfile();
  },
  methods: {
    async fetchUserProfile() {
      try {
        const response = await axios.get('/api/user-profile');
        this.user = response.data;
      } catch (error) {
        console.error('個人情報を取得に失敗しました:', error);
      }
    },
    async saveProfile() {
      try {
        const response = await axios.put('/api/user-profile', {
          user_id: this.user.ID,  // 包含 user_id
          COMPANY_NAME: this.user.COMPANY_NAME,
          PASSWORD: this.newPassword
        });
        if (response.data.message === 'User profile updated successfully') {
          this.isEditing = false;
          this.newPassword = '';
        }
      } catch (error) {
        console.error('個人情報を更新に失敗しました:', error);
      }
    }
  }
};
</script>