<template>
  <div class="container mt-5">
    <h2>Key管理</h2>
    <table class="table table-striped">
      <thead>
        <tr>
          <th class="col-md-1">KEY ID</th>
          <th class="col-md-1">会社名</th>
          <th class="col-md-1">Key状態</th>
          <th class="col-md-1">名刺権限</th>
          <th class="col-md-1">配信権限</th>
          <th class="col-md-1">領収書権限</th>
          <th class="col-md-1">名刺利用量</th>
          <th class="col-md-1">利用制限</th>
          <th class="col-md-1">配信利用量</th>
          <th class="col-md-1">配信制限</th>
          <th class="col-md-1">領収書利用量</th>
          <th class="col-md-1">領収書制限</th>
          <th class="col-md-2">管理</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="key in keys" :key="key.KEY_ID">
          <td>{{ key.KEY_ID }}</td>
          <td><router-link :to="`/admin-dashboard/key-detail/${key.KEY_ID}`">{{ key.COMPANY_NAME }}</router-link></td>
          <td v-if="!key.editing">{{ key.STATUS }}</td>
          <td v-else><input v-model="key.STATUS" class="form-control" /></td>
          <td v-if="!key.editing">{{ key.MEISHI_PERMISSION ? 'あり' : 'なし' }}</td>
          <td v-else>
            <select v-model="key.MEISHI_PERMISSION" class="form-select">
              <option :value="true">あり</option>
              <option :value="false">なし</option>
            </select>
          </td>
          <td v-if="!key.editing">{{ key.MAIL_PERMISSION ? 'あり' : 'なし' }}</td>
          <td v-else>
            <select v-model="key.MAIL_PERMISSION" class="form-select">
              <option :value="true">あり</option>
              <option :value="false">なし</option>
            </select>
          </td>
          <td v-if="!key.editing">{{ key.RECEIPT_PERMISSION ? 'あり' : 'なし' }}</td>
          <td v-else>
            <select v-model="key.RECEIPT_PERMISSION" class="form-select">
              <option :value="true">あり</option>
              <option :value="false">なし</option>
            </select>
          </td>
          <td v-if="!key.editing">{{ key.CURRENT_USAGE }}</td>
          <td v-else><input v-model="key.CURRENT_USAGE" class="form-control" /></td>
          <td v-if="!key.editing">{{ key.TOTAL_USAGE_LIMIT }}</td>
          <td v-else><input v-model="key.TOTAL_USAGE_LIMIT" class="form-control" /></td>
          <td v-if="!key.editing">{{ key.MAIL_USAGE }}</td>
          <td v-else><input v-model="key.MAIL_USAGE" class="form-control" /></td>
          <td v-if="!key.editing">{{ key.MAIL_USAGE_LIMIT }}</td>
          <td v-else><input v-model="key.MAIL_USAGE_LIMIT" class="form-control" /></td>
          <td v-if="!key.editing">{{ key.RECEIPT_USAGE }}</td>
          <td v-else><input v-model="key.RECEIPT_USAGE" class="form-control" /></td>
          <td v-if="!key.editing">{{ key.RECEIPT_USAGE_LIMIT }}</td>
          <td v-else><input v-model="key.RECEIPT_USAGE_LIMIT" class="form-control" /></td>
          <td>
            <button @click="toggleEdit(key)" class="btn btn-primary me-2">{{ key.editing ? '保存' : '編集' }}</button>
            <button @click="deleteKey(key.KEY_ID)" class="btn btn-danger">削除</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from '@/axios'; // 使用创建的 axios 实例

export default {
  data() {
    return {
      keys: []
    };
  },
  created() {
    this.fetchKeys();
  },
  methods: {
    async fetchKeys() {
      try {
        const response = await axios.get('/api/keys');
        this.keys = response.data.map(key => ({ ...key, editing: false }));
      } catch (error) {
        console.error('Keyリストの取得に失敗しました:', error);
      }
    },
    toggleEdit(key) {
      if (key.editing) {
        this.saveKey(key);
      }
      key.editing = !key.editing;
    },
    async saveKey(key) {
      try {
        // 直接传递布尔值
        await axios.put(`/api/keys/${key.KEY_ID}`, key);
        // 重新获取数据以更新视图
        this.fetchKeys();
      } catch (error) {
        console.error('Keyを保存に失敗しました:', error);
      }
    },
    async deleteKey(keyId) {
      if (confirm('削除を確認しますか?')) {
        try {
          await axios.delete(`/api/keys/${keyId}`);
          this.fetchKeys();
        } catch (error) {
          console.error('Keyを削除失敗しました:', error);
        }
      }
    }
  }
};
</script>
<!-- <template>
  <div class="container mt-5">
    <h2>Key管理</h2>
    <table class="table table-striped">
      <thead>
        <tr>
          <th class="col-md-2">KEY ID</th>
          <th class="col-md-2">会社名</th>
          <th class="col-md-1">Key状態</th>
          <th class="col-md-1">名刺権限</th>
          <th class="col-md-1">配信権限</th>
          <th class="col-md-1">名刺利用量</th>
          <th class="col-md-1">利用制限</th>
          <th class="col-md-1">配信利用量</th>
          <th class="col-md-2">管理</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="key in keys" :key="key.KEY_ID">
          <td>{{ key.KEY_ID }}</td>
          <td><router-link :to="`/admin-dashboard/key-detail/${key.KEY_ID}`">{{ key.COMPANY_NAME }}</router-link></td>
          <td v-if="!key.editing">{{ key.STATUS }}</td>
          <td v-else><input v-model="key.STATUS" class="form-control" /></td>
          <td v-if="!key.editing">{{ key.MEISHI_PERMISSION }}</td>
          <td v-else><input type="checkbox" v-model="key.MEISHI_PERMISSION" /></td>
          <td v-if="!key.editing">{{ key.MAIL_PERMISSION }}</td>
          <td v-else><input type="checkbox" v-model="key.MAIL_PERMISSION" /></td>
          <td v-if="!key.editing">{{ key.CURRENT_USAGE }}</td>
          <td v-else><input v-model="key.CURRENT_USAGE" class="form-control" /></td>
          <td v-if="!key.editing">{{ key.TOTAL_USAGE_LIMIT }}</td>
          <td v-else><input v-model="key.TOTAL_USAGE_LIMIT" class="form-control" /></td>
          <td v-if="!key.editing">{{ key.MAIL_USAGE }}</td>
          <td v-else><input v-model="key.MAIL_USAGE" class="form-control" /></td>
          <td>
            <button @click="toggleEdit(key)" class="btn btn-primary me-2">{{ key.editing ? '保存' : '編集' }}</button>
            <button @click="deleteKey(key.KEY_ID)" class="btn btn-danger">削除</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from '@/axios'; // 使用创建的 axios 实例

export default {
  data() {
    return {
      keys: []
    };
  },
  created() {
    this.fetchKeys();
  },
  methods: {
    async fetchKeys() {
      try {
        const response = await axios.get('/api/keys');
        this.keys = response.data.map(key => ({ ...key, editing: false }));
      } catch (error) {
        console.error('Keyリストの取得に失敗しました:', error);
      }
    },
    toggleEdit(key) {
      if (key.editing) {
        this.saveKey(key);
      }
      key.editing = !key.editing;
    },
    async saveKey(key) {
      try {
        await axios.put(`/api/keys/${key.KEY_ID}`, key);
      } catch (error) {
        console.error('Keyを保存に失敗しました:', error);
      }
    },
    async deleteKey(keyId) {
      if (confirm('削除を確認しますか?')) {
        try {
          await axios.delete(`/api/keys/${keyId}`);
          this.fetchKeys();
        } catch (error) {
          console.error('Keyを削除失敗しました:', error);
        }
      }
    }
  }
};
</script> -->