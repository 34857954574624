import { createRouter, createWebHistory } from 'vue-router';
import AdminLogin from '../components/AdminLogin.vue';
import AdminDashboard from '../components/AdminDashboard.vue';
import KeyList from '../components/KeyList.vue';
import KeyManage from '../components/KeyManage.vue';
import KeyAdd from '../components/KeyAdd.vue';
import KeyDetail from '../components/KeyDetail.vue';
import UserList from '../components/UserList.vue';
import UserManage from '../components/UserManage.vue';
import UserAdd from '../components/UserAdd.vue';
import UserDetail from '../components/UserDetail.vue';
import UserLogin from '../components/UserLogin.vue';
import UserDashboard from '../components/UserDashboard.vue';
import MyKeys from '../components/MyKeys.vue';
import MyProfile from '../components/MyProfile.vue';
import UserRegister from '../components/UserRegister.vue';
import MyKeyDetail from '@/components/MyKeyDetail.vue';

const routes = [
  { path: '/', redirect: '/user-login' },
  { path: '/admin-login', component: AdminLogin },
  {
    path: '/admin-dashboard',
    component: AdminDashboard,
    children: [
      { path: 'key-list', component: KeyList },
      { path: 'key-manage', component: KeyManage },
      { path: 'key-add', component: KeyAdd },
      { path: 'key-detail/:key_id', component: KeyDetail },
      { path: 'user-list', component: UserList },
      { path: 'user-manage', component: UserManage },
      { path: 'user-add', component: UserAdd },
      { path: 'user-detail/:user_id', component: UserDetail }
    ]
  },
  { path: '/user-login', component: UserLogin },
  {
    path: '/user-dashboard',
    component: UserDashboard,
    children: [
      { path: 'my-keys', component: MyKeys },
      { path: 'my-profile', component: MyProfile },
      { path: 'key-detail/:key_id', component: MyKeyDetail }
    ]
  },
  { path: '/user-register', component: UserRegister }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;

// import { createRouter, createWebHistory } from 'vue-router';
// import AdminLogin from '../components/AdminLogin.vue';
// import AdminDashboard from '../components/AdminDashboard.vue';
// import KeyList from '../components/KeyList.vue';
// import KeyManage from '../components/KeyManage.vue';
// import KeyAdd from '../components/KeyAdd.vue';
// import KeyDetail from '../components/KeyDetail.vue';
// import UserList from '../components/UserList.vue';
// import UserManage from '../components/UserManage.vue';
// import UserAdd from '../components/UserAdd.vue';
// import UserDetail from '../components/UserDetail.vue';
// import UserLogin from '../components/UserLogin.vue';
// import UserDashboard from '../components/UserDashboard.vue';
// import MyKeys from '../components/MyKeys.vue';
// import MyProfile from '../components/MyProfile.vue';
// import UserRegister from '../components/UserRegister.vue';

// const routes = [
//   { path: '/', redirect: '/user-login' },
//   { path: '/admin-login', component: AdminLogin },
//   {
//     path: '/admin-dashboard',
//     component: AdminDashboard,
//     children: [
//       { path: 'key-list', component: KeyList },
//       { path: 'key-manage', component: KeyManage },
//       { path: 'key-add', component: KeyAdd },
//       { path: 'key-detail/:key_id', component: KeyDetail },
//       { path: 'user-list', component: UserList },
//       { path: 'user-manage', component: UserManage },
//       { path: 'user-add', component: UserAdd },
//       { path: 'user-detail/:user_id', component: UserDetail }
//     ]
//   },
//   { path: '/user-login', component: UserLogin },
//   {
//     path: '/user-dashboard',
//     component: UserDashboard,
//     children: [
//       { path: 'my-keys', component: MyKeys },
//       { path: 'my-profile', component: MyProfile }
//     ]
//   },
//   { path: '/user-register', component: UserRegister }
// ];

// const router = createRouter({
//   history: createWebHistory(),
//   routes
// });

// export default router;

// import { createRouter, createWebHistory } from 'vue-router';
// import AdminLogin from '../components/AdminLogin.vue';
// import AdminDashboard from '../components/AdminDashboard.vue';
// import KeyList from '../components/KeyList.vue';
// import KeyManage from '../components/KeyManage.vue';
// import KeyAdd from '../components/KeyAdd.vue';
// import KeyDetail from '../components/KeyDetail.vue';
// import UserList from '../components/UserList.vue';
// import UserManage from '../components/UserManage.vue';
// import UserAdd from '../components/UserAdd.vue';
// import UserDetail from '../components/UserDetail.vue';
// import UserLogin from '../components/UserLogin.vue';
// import UserDashboard from '../components/UserDashboard.vue';
// import MyKeys from '../components/MyKeys.vue';
// import MyProfile from '../components/MyProfile.vue';
// import UserRegister from '../components/UserRegister.vue';

// const routes = [
//   { path: '/', redirect: '/user-login' },
//   { path: '/admin-login', component: AdminLogin },
//   {
//     path: '/admin-dashboard',
//     component: AdminDashboard,
//     children: [
//       { path: 'key-list', component: KeyList },
//       { path: 'key-manage', component: KeyManage },
//       { path: 'key-add', component: KeyAdd },
//       { path: 'key-detail/:key_id', component: KeyDetail },
//       { path: 'user-list', component: UserList },
//       { path: 'user-manage', component: UserManage },
//       { path: 'user-add', component: UserAdd },
//       { path: 'user-detail/:user_id', component: UserDetail }
//     ]
//   },
//   { path: '/user-login', component: UserLogin },
//   {
//     path: '/user-dashboard',
//     component: UserDashboard,
//     children: [
//       { path: 'my-keys', component: MyKeys },
//       { path: 'my-profile', component: MyProfile }
//     ]
//   },
//   { path: '/user-register', component: UserRegister }
// ];

// const router = createRouter({
//   history: createWebHistory(),
//   routes
// });

// export default router;

// import { createRouter, createWebHistory } from 'vue-router';
// import AdminLogin from '../components/AdminLogin.vue';
// import AdminDashboard from '../components/AdminDashboard.vue';
// import KeyList from '../components/KeyList.vue';
// import KeyManage from '../components/KeyManage.vue';
// import KeyAdd from '../components/KeyAdd.vue';
// import KeyDetail from '../components/KeyDetail.vue';
// import UserList from '../components/UserList.vue';
// import UserManage from '../components/UserManage.vue';
// import UserAdd from '../components/UserAdd.vue';
// import UserDetail from '../components/UserDetail.vue';
// import UserLogin from '../components/UserLogin.vue';
// import UserDashboard from '../components/UserDashboard.vue';
// import MyKeys from '../components/MyKeys.vue';
// import MyProfile from '../components/MyProfile.vue';
// import UserRegister from '../components/UserRegister.vue';

// const routes = [
//   { path: '/', redirect: '/user-login' },
//   { path: '/admin-login', component: AdminLogin },
//   {
//     path: '/admin-dashboard',
//     component: AdminDashboard,
//     children: [
//       { path: 'key-list', component: KeyList },
//       { path: 'key-manage', component: KeyManage },
//       { path: 'key-add', component: KeyAdd },
//       { path: 'key-detail/:key_id', component: KeyDetail },
//       { path: 'user-list', component: UserList },
//       { path: 'user-manage', component: UserManage },
//       { path: 'user-add', component: UserAdd },
//       { path: 'user-detail/:user_id', component: UserDetail }
//     ]
//   },
//   { path: '/user-login', component: UserLogin },
//   {
//     path: '/user-dashboard',
//     component: UserDashboard,
//     children: [
//       { path: 'my-keys', component: MyKeys },
//       { path: 'my-profile', component: MyProfile }
//     ]
//   },
//   { path: '/user-register', component: UserRegister }
// ];

// const router = createRouter({
//   history: createWebHistory(),
//   routes
// });

// export default router;

// import { createRouter, createWebHistory } from 'vue-router';
// import AdminLogin from '../components/AdminLogin.vue';
// import AdminDashboard from '../components/AdminDashboard.vue';
// import KeyList from '../components/KeyList.vue';
// import KeyManage from '../components/KeyManage.vue';
// import KeyAdd from '../components/KeyAdd.vue';
// import KeyDetail from '../components/KeyDetail.vue';
// import UserList from '../components/UserList.vue';
// import UserManage from '../components/UserManage.vue';
// import UserAdd from '../components/UserAdd.vue';
// import UserDetail from '../components/UserDetail.vue';
// import UserLogin from '../components/UserLogin.vue';
// import UserDashboard from '../components/UserDashboard.vue';
// import MyKeys from '../components/MyKeys.vue';
// import MyProfile from '../components/MyProfile.vue';
// import UserRegister from '../components/UserRegister.vue'; // 更新导入路径

// const routes = [
//   { path: '/', redirect: '/user-login' },
//   { path: '/admin-login', component: AdminLogin },
//   {
//     path: '/admin-dashboard',
//     component: AdminDashboard,
//     children: [
//       { path: 'key-list', component: KeyList },
//       { path: 'key-manage', component: KeyManage },
//       { path: 'key-add', component: KeyAdd },
//       { path: 'key-detail/:key_value', component: KeyDetail },
//       { path: 'user-list', component: UserList },
//       { path: 'user-manage', component: UserManage },
//       { path: 'user-add', component: UserAdd },
//       { path: 'user-detail/:user_id', component: UserDetail }
//     ]
//   },
//   { path: '/user-login', component: UserLogin },
//   {
//     path: '/user-dashboard',
//     component: UserDashboard,
//     children: [
//       { path: 'my-keys', component: MyKeys },
//       { path: 'my-profile', component: MyProfile }
//     ]
//   },
//   { path: '/user-register', component: UserRegister } 
// ];

// const router = createRouter({
//   history: createWebHistory(),
//   routes
// });

// export default router;